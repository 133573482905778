
import React from "react";
import { Heading, Button, Flex, Box, Text} from 'rebass';
import { Label, Input,Radio,Select } from '@rebass/forms';

import { ThemeProvider } from 'emotion-theming'
import preset from '@rebass/preset'

import ReactGridLayout from 'react-grid-layout';

import { /*DndProvider, */ useDrag,useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch'

// for async button actions ..I guess
import 'regenerator-runtime/runtime'

//import * as logic from './logic.jsx';
//import * as remote from './remote.jsx';
import Bored from './board.jsx'

//import Pawn from './pawn.jsx'
import WhitePawn from './whitePawn.svg'
import WhiteQueen from './whiteQueen.svg'
import WhiteRook from './whiteRook.svg'
import WhiteKing from './whiteKing.svg'
import WhiteKnightL from './whiteKnightL.svg'
import WhiteKnightR from './whiteKnightR.svg'
import WhiteBishop from './whiteBishop.svg'

import BlackPawn from './blackPawn.svg'
import BlackQueen from './blackQueen.svg'
import BlackRook from './blackRook.svg'
import BlackKing from './blackKing.svg'
import BlackKnightR from './blackKnightR.svg'
import BlackKnightL from './blackKnightL.svg'
import BlackBishop from './blackBishop.svg'


//console.log("logic is ", logic)
//console.log("deal is ", deal)

class Wrap extends React.Component {
	state = {
		hasError: false,
	}
	constructor(props) {
		super(props)
		this.state = {hasError: false}
	}

	static getDerivedStateFromError(error) {
		return { hasError: true }
	}
	render() {
		if (this.state.hasError) {
			return <h1>something broke</h1>
		}
		return this.props.children
	}
}


const pawn='pawn'
const rook='rook'
const knight='knight'
const bishop='bishop'
const queen='queen'
const king='king'
const white='white'
const black='black'

let newBoard = () => {
	console.log("newboard")
	let set={
		white:[],
		black:[],
	}
	for (let c = 0; c < 8; c++) {
		set.white.push({ x:c, y:1, p:pawn, col:white })
		set.black.push({ x:c, y:6, p:pawn, col:black})
	}
	for (const [side,r] of [[white,0],[black,7]]) {
		let p = [rook,knight,bishop,king,queen,bishop,knight,rook]
		for (let c = 0; c < 8; c++) {
			set[side].push({col:side, p:p[c], x:c, y:r, left:c<=4})
		}
	}
	
	console.log("newboard->",set)
	return set
}

const s2b = set => {
	console.log("s2b", set)
	let b=[]
	for (let c = 0; c < 8; c++) {
		b[c]=[]
	}
	let stash={
		[black]:[],
		[white]:[],
	}
	for (const side of [black,white]) {
		for (const p of set[side]) {
			if (p.x>=0) {
				b[p.x][p.y] = p
			} else {
				stash[side].push(p)
			}
		}
	}
	return {
		board:b,
		stash:stash,
	}
}


let srv = "https://vzvu8way7d.execute-api.us-east-1.amazonaws.com/prod"

const get = async (gs, pos) => {
	let rsp = await fetch(srv + "/game/dadkev/", {
		//method: 'GET',
		mode: 'cors',
	})
	console.log("do get -> ", rsp.status)
	try {
		let json = await rsp.json()
		console.log("body-> ",json)
		return json
	} catch (e) {
		console.log("nope-> ",e)
		return {state:{set:{black:[],white:[]}}}
	}
}

let post = async (set,seq) => {
	let rsp = await fetch(srv + "/game/dadkev", {
		method: 'POST',
		mode: 'cors',
		body: JSON.stringify({state:{set:set,seq:seq}})
	})
	console.log("send ",set,"seq",seq," -> ", rsp.status)
	let json = await rsp.json()
	console.log("body-> ",json)
	return json
}

let timer = null
let flipped = false

const App = () => {
	const [state,setState] = React.useState({
		game:null, 
	})
	const [flip,setFlip] = React.useState(false)
	console.log("app state:", state)

	flipped = flip

	let gs = null

	let newStash = () => ({
		[white]: {}, 
		[black]: {}, //piece->n
	})
	if (!state.init && state.game == null) {
		get().then(s_=>{
			let s = s_.state
			console.log("init state",s)
			setState({game:{set:s.set,seq:s.seq}})
		})
		return (<div>..</div>)
	}

	gs = state.game
	if (gs == null) {
		return (<div>duh...</div>)
	}
	console.log("stateis",gs)
	let {board,stash} = s2b(gs.set)
	console.log("board->",board, "stash->",stash)

	let seq = 0
	if (gs.seq && gs.seq > seq) {
		seq = gs.seq
	}
	console.log("seq is ", seq)
	let g = {
		b: () => board,
		stash: () => stash,
		gs: () => gs,
		update: (set) => {
			console.log("set state to ", set)
			seq++
			setState({game:{set:{...set},seq:seq}})
			post(set,seq)
		},
	}

	let refresh = () => {
		timer = null
		get().then(s=>{
			s = s.state
			console.log("timer refresh->",s)
			if (s.seq > seq)
				setState({game:{set:s.set,seq:s.seq}})
			else {
				if (!timer)
					timer = setTimeout(refresh, 2000)
			}

		})
	}
	if (!timer)
		timer = setTimeout(refresh, 2000)

	let isMobile = window.innerWidth < 600;
	isMobile = false
	return (
		<Wrap>
			<ThemeProvider theme={preset}>
			<Flex flexWrap='wrap'>
			<Button onClick = { e=> g.update(newBoard()) } >New Game</Button>
			<Button onClick = { e=> {setFlip(!flip); console.log("flip->",flip)} } >Flip</Button>
			<Button onClick = { e=> post(gs.set) } >Save</Button>
			</Flex>

			<DndProvider options={HTML5toTouch}>
			<Stash g={g} col={flip?black:white}/>
			<Board g={g} flip={flip}/>
			<Stash g={g} col={flip?white:black}/>
			</DndProvider>

			</ThemeProvider>
		</Wrap>
	)
}

// square with piece P in it,
// 
const Square = ({g,p}) => {
	let b = g.b()
	let [{isOver,canDrop},drop] = useDrop({
		accept: 'piece',
		canDrop: (item) => {
			console.log("candrop",item,"onto",p)
			let m = item.p
			if (p.x<0) return m.x >= 0; // move off board into stash
			if (m.x<0) return p.x >= 0; //move from stash onto board

			if (!p.p) return true // empty
			//console.log("old ", b[item.x][item.y], "clobbering", b[x][y])
			return b[p.x][p.y].col != b[m.x][m.y].col
		},
		drop: (item) => {
			let m=item.p
			console.log("move ", m, " onto ", p)
			m.x=p.x
			m.y=p.y
			p.x=-1
			g.update(g.gs().set)
		},
		collect: monitor => ({
			isOver: !!monitor.isOver(),
			canDrop: !!monitor.canDrop(),
		})
	})

	let col = (p.x+p.y)%2 ? 'olive':'white';
	if (p.x<0) col='white'
	let border = 'none'
	if (isOver) {
		console.log("is over ",p.x,p.y, "candrop=",canDrop)
		col = canDrop ? 'yellow' : 'red'
	} else if (canDrop) {
		//col = 'green'
		//border = 'solid'
	}

	let piece = pieceFor(p)
		

	return (
			<div
		ref={drop}
		style={{
			backgroundColor:col,//padding:'10%',
			borderStyle: border,
			borderColor: 'black'
			}}>
			{piece}
		</div>
	)
}

// p={p:pawn|..,col:..,left:bool} -> piece (svg)
let pieceFor = p => {
	if (!p.p) return (<div></div>);
	
	let wp = {
		pawn: <WhitePawn/>,
		queen: <WhiteQueen/>,
		rook: <WhiteRook/>,
		bishop: <WhiteBishop/>,
		knight: <WhiteKnightL/>,
		king: <WhiteKing/>,
		'kr':<WhiteKnightR/>
	}
	let bp = {
		pawn: <BlackPawn/>,
		queen: <BlackQueen/>,
		rook: <BlackRook/>,
		bishop: <BlackBishop/>,
		knight: <BlackKnightL/>,
		king: <BlackKing/>,
		'kr':<BlackKnightR/>
	}
	let s={
		[black]:bp,
		[white]:wp,
	}

	let is = s[p.col][p.p]
	if (p.p == knight) {
		let r = !p.left
		if (flipped) r = !r
		is = s[p.col][r ? 'kr' : knight]
	}
	return (<Piece is={is} p={p}/>)
}

const Piece = ({is,p}) => {
	const [{isDragging},drag,preview] = useDrag({
		item: { type: 'piece', p:p },
		collect: (monitor) => {
			return {
				isDragging:!!monitor.isDragging(),
			}
		},
	})
	return (
			<div ref={drag}
		style={{
			opacity: isDragging ? 0.5 : 1,
			backgroundColor: 'transparent',
			borderStyle: isDragging ? 'solid' : 'none',
			borderColor: 'black'
		}}>
			{is}
			</div>
	)
}


const Board = ({g,flip}) => {
	//svg = svg.append("g")
	//	.attr("transform", "scale(1,1)")
	let sq=[]
	//return (<Square x={0} y={0}><WhitePawn/></Square>)
	
	let b = g.b()
	for (let r = 0; r < 8; r++)  {
		let row = []
		for (let c = 0; c < 8; c++) {
			let p = b[c][r]
			if (!p) p = {x:c,y:r,p:null}
			sq.push(<Square g={g} p={p}/>)
		}
		//sq.push(<Box width={1} key={`r${r}`}>{row}</Box>)
	}
	if (flip) {
		console.log("flipping!")
		sq = sq.reverse()
	}
	console.log(sq)
	return (<div style={{
		'display':'grid',
		'gridTemplateColumns': '50px 50px 50px 50px 50px 50px 50px 50px',
		'gridTemplateRows': '50px 50px 50px 50px 50px 50px 50px 50px',
	}}>
 			{sq}
			</div>
		   )
}
let Stash = ({g,col}) => {
	let stash = g.stash()
	console.log("stash is", stash)
	console.log("stash.. is", stash[col])

	let sq=[]
	for (const p of stash[col]) {
		sq.push(<Square g={g} p={p}/>)
	}
	console.log("stash is", sq)
	return (<div style={{
		'display':'grid',
		'gridTemplateColumns': '30px 30px 30px 30px 30px 30px 30px 30px',
		'gridTemplateRows': '30px'
	}}>
 			{sq}
			</div>
		   )
}

const Grid = () => {
	let l = [
		{i:'a', x:0,y:1,w:1,h:1, static:true},
		{i:'b', x:1,y:1,w:1,h:1, minW:1, maxW:4},
		{i:'c', x:2,y:1,w:1,h:1},
	];
	l = [
		{i:'a', x:0,y:0,w:1,h:1, static:true},
		{i:'b', x:1,y:0,w:1,h:1, static:true}, //minW:2, maxW:4},
		{i:'c', x:2,y:0,w:1,h:2},
	];
	
	return (
		<ReactGridLayout clasName='layout'  layout={l} cols={8} rowHeight={1} width={1000}>
			<Box key={'a'} sx={{borderStyle:'solid', borderColor:'black'}}><WhitePawn/></Box>
			<Box key={'b'} sx={{background:'grey'}}><WhitePawn/></Box>
		<div key={'c'}><WhitePawn/></div>
		</ReactGridLayout>
)
}


//export default hot(module)(App);
export default App;
