
import * as React from 'react'
import * as d3 from "d3"
import * as dh from "d3-hexbin"


const mk = (ref, data, options) => {
	  // data=[ [{bits:"01010",at:50}, ...],..]

	let div = d3.select(ref)
	div.selectAll("*").remove()
	let cfg = {
		margin: {top: 20, right: 20, bottom: 20, left: 20},
		w: 10,
		h: 50,
		max: 50,
		col: d3.scaleOrdinal(d3.schemeTableau10),
		...options
	}

	var svg = div.append("svg")
		.attr("viewBox", [0,0,8,8])
	board(svg)
}

let sq = (x1,y1,x2,y2,c) => {
	return d3.create('svg:rect')
		.attr('x',x1).attr('y',y1)
		.attr('width', x2-x1).attr('height', y2-y1)
		.attr('fill',c).attr('fill-opacity',0.5)
		.attr('stroke','black').attr('stroke-opacity',0.8)
		.attr('stroke-width',0.05)
}

let circ = (x,y,r) => {
	return d3.create('svg:circle')
		.attr('cx',x).attr('cy',y)
		.attr('r', r)
		.attr('fill','red')
		.attr('stroke','black')
		.attr('stroke-width',0.05)
}

let a = (s,o) => {
	let f = () => o.node()
	return s.append(f)
}
	
let board = (svg) => {
	//svg = svg.append("g")
	//	.attr("transform", "scale(1,1)")
	for (let r = 0; r < 8; r++)  {
		for (let c = 0; c < 8; c++) {
			let bw = (c+r)%2 ? 'white':'black'
			a(svg,circ(c+0.5,r+0.5,0.5))
			svg.append("circle").attr("cx",c).attr("cy",r).attr("r",0.3).attr('fill','yellow')
			a(svg,sq(c,r,c+1,r+1,bw))
		}
	}
	//svg.append("circle").attr("r",10).attr("fill",'red')
}


const T = (props) => {
	const [svg,setSvg] = React.useState(null)
    let ref = React.createRef()
	var col = d3.scaleOrdinal(d3.schemeTableau10)
		//.range(["#EDC951","#CC333F","#00A0B0"]);
				
	var options = {
		//margin: 10,
		max: 500,
		col: col
	};

	// create chart post render
	React.useEffect(() => {
		//let d = props.data ?? data //store.data ? store.data : data
		console.log('render thing in ', ref, 'p=>', props)
		mk(ref.current, null, options)
		return () => {
			console.log("photon should remove svg")
		}
	})

	return (
			<div id="thingy" ref={ref}/>
	)
}


export default T
